import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { MdLogout } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
const NextPageOfLogin = () => {
    const history = useNavigate();
    // useEffect(() => {

    //     const isLoggedIn = localStorage.getItem('isLoggedIn');
    //     if (!isLoggedIn) {
    //         history("/")
    //     } else {
    //         // setLoggedInStatus(isLoggedIn);
    //     }
    // }, [history])
    const logOut = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`, { withCredentials: true })
            console.log(res.data);
            history("/")
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <>
            <Container className="m-auto ">
                <Row className="d-flex justify-content-center align-items-center text-center mt-5">
                    <Col className="d-flex justify-content-between mt-5">
                        <h1 className="text-center">Choose which part you want to change</h1>
                        <Button onClick={logOut} className="p-3 " variant="danger"> <MdLogout size={20} /> </Button>
                    </Col>
                </Row>
                <Row className="mt-5"> {/* Responsive grid layout */}
                    {/* <Container className="random"> */}

                    <Col className="col-lg-3- col-xs-4">
                        <Link className="link-style" to="/invoice">
                            <div className="box">
                                Invoice
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/token">
                            <div className="box">
                                Token
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/notification">
                            <div className="box">
                                Notification
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/crm">
                            <div className="box">
                                CRM
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/doc">
                            <div className="box">
                                Documents
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/report">
                            <div className="box">
                                Report
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/products">
                            <div className="box">
                                Products
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/transactions">
                            <div className="box">
                                Transactions
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/transfer">
                            <div className="box">
                                Transfer
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/commission">
                            <div className="box">
                                Commission
                            </div>
                        </Link>
                    </Col>
                    <Col className="col-lg-3 col-xs-4">
                        <Link className="link-style" to="/coupons">
                            <div className="box">
                                Coupons
                            </div>
                        </Link>
                    </Col>
                    {/* </Container> */}
                </Row>
            </Container>
        </>
    )
}
export default NextPageOfLogin
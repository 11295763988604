import React, { useState } from 'react';
import { Card, Button, Collapse } from 'react-bootstrap';
import useGetCoupons from './hooks/useGetCoupons';
import formatDate from '../../components/formatDate';
import NewCouponModal from './modals/NewCouponModal';
import { FaChevronUp } from "react-icons/fa6";
import { FaChevronDown } from "react-icons/fa6";

const Coupon = () => {
    const [expandedItems, setExpandedItems] = useState({});
    const [page, setPage] = useState(0);
    const [option, setOption] = useState("");
    const [show, setShow] = useState(false);

    const handleSelectChange = (e) => {
        setOption(e.target.value);
        setPage(0)
    };
    const {
        coupons,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
    } = useGetCoupons(page, option)
    // console.log(cc);

    const toggleExpand = (id) => {
        setExpandedItems(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
        console.log(expandedItems);

    };



    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Coupon List</h1>
            <div className="flex justify-between items-center mb-4">
                <button onClick={() => setShow(true)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add New Coupon
                </button>

                <div className="flex items-center">

                    <select
                        id="filter"
                        value={option}
                        onChange={handleSelectChange}
                        className="block p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                        <option value="all">All</option>
                        <option value="used">Used</option>
                        <option value="not_used">Not Used</option>
                    </select>
                </div>
            </div>
            <div className="space-y-4">
                {isLoading ? (
                    <div className="bg-gray-300  mb-3 border rounded-lg shadow-lg animate-pulse">
                        <div className="bg-gray-300 p-4 flex justify-between items-center">
                            <div className="bg-gray-400 h-4 w-32 rounded"></div> 
                            <div className="bg-gray-400 h-4 w-16 rounded"></div> 
                        </div>
                        <div className="p-4">
                            <div className="bg-gray-400 h-3 w-40 mb-2 rounded"></div>
                            <div className="bg-gray-400 h-3 w-32 mb-4 rounded"></div>

                            <div className="flex justify-start items-center">
                                <div className="bg-gray-400 h-8 w-24 rounded"></div>
                            </div>

                            
                        </div>
                    </div>
                ) : isError ? (
                    <h1>error</h1>
                ) :
                    coupons && Array.isArray(coupons) &&
                    coupons.map((coupon) => (
                        <Card key={coupon.id} className="mb-3">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <span className="font-weight-bold">Coupon({coupon.children_detail.length}): {coupon.coupon}</span>

                                <span
                                    className={`px-2 py-1 rounded-full text-sm font-semibold ${coupon.used_at ? "bg-gray-500 text-white" : "bg-blue-500 text-white"
                                        }`}
                                >
                                    {coupon.used_at ? "Used" : "Available"}
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Discount: {coupon.discount_percentage * 100}%
                                    <br />
                                    Used at: <span className={`${!formatDate(coupon.used_at) && "text-red-400"}`}> {formatDate(coupon.used_at) ? formatDate(coupon.used_at) : "Not Used"}</span>
                                </Card.Text>
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => toggleExpand(coupon.id)}
                                    className="d-flex align-items-center"
                                >
                                    {expandedItems[coupon.id] ? (
                                        <>
                                            Hide Children
                                            <FaChevronUp className="ml-2" />
                                        </>
                                    ) : (
                                        <>
                                            Show Children
                                            <FaChevronDown className="ml-2" />
                                        </>
                                    )}
                                </Button>
                                <Collapse in={expandedItems[coupon.id]}>
                                    <div className="mt-3">
                                        {coupon.children_detail.map((child) => (
                                            <Card key={child.children_id} className="mb-2">
                                                <Card.Body>
                                                    <Card.Text>
                                                        Child Coupon: {child.coupon}
                                                        <br />
                                                        Discount: {child.discount_percentage * 100}%
                                                        <br />
                                                        Used at: <span className={`${!formatDate(child.used_at) && "text-red-400"}`}>{formatDate(child.used_at) ? formatDate(child.used_at) : "Not Used"}</span>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                </Collapse>
                            </Card.Body>
                        </Card>
                    ))
                }
                {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {coupons.map((coupon) => (
                        <Card key={coupon.id} className="mb-3">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <span className="font-weight-bold">Coupon({coupon.children_detail.length}): {coupon.coupon}</span>

                                <span
                                    className={`px-2 py-1 rounded-full text-sm font-semibold ${coupon.used_at ? "bg-gray-500 text-white" : "bg-blue-500 text-white"}`}
                                >
                                    {coupon.used_at ? "Used" : "Available"}
                                </span>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    Discount: {coupon.discount_percentage * 100}%
                                    <br />
                                    Used at: <span className={`${!formatDate(coupon.used_at) && "text-red-400"}`}>{formatDate(coupon.used_at) ? formatDate(coupon.used_at) : "Not Used"}</span>
                                </Card.Text>
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => toggleExpand(coupon.id)}
                                    className="d-flex align-items-center"
                                >
                                    {expandedItems[coupon.id] ? (
                                        <>
                                            Hide Children
                                        </>
                                    ) : (
                                        <>
                                            Show Children
                                        </>
                                    )}
                                </Button>
                                <Collapse in={expandedItems[coupon.id]}>
                                    <div className="mt-3">
                                        {coupon.children_detail.map((child) => (
                                            <Card key={child.children_id} className="mb-2">
                                                <Card.Body>
                                                    <Card.Text>
                                                        Child Coupon: {child.coupon}
                                                        <br />
                                                        Discount: {child.discount_percentage * 100}%
                                                        <br />
                                                        Used at: <span className={`${!formatDate(child.used_at) && "text-red-400"}`}>{formatDate(child.used_at) ? formatDate(child.used_at) : "Not Used"}</span>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        ))}
                                    </div>
                                </Collapse>
                            </Card.Body>
                        </Card>
                    ))}
                </div> */}

                <div className="mt-4 flex justify-between">
                    <button
                        onClick={() => { if (page !== 0) setPage(prev => prev - 1) }}
                        disabled={page === 0}
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={!isNext}
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Next
                    </button>
                </div>
            </div>
            <NewCouponModal refetch={refetch} show={show} setShow={setShow} />
        </div>
    );
};

export default Coupon
import React from "react"
import { useState } from "react";
import Table from 'react-bootstrap/Table';

import { Container, Row, Col } from 'react-bootstrap';
import {  useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import {  ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import DocDetailModal from "./DocDetailModal";
import Spinner from 'react-bootstrap/Spinner';

const Document = () => {
    const [show, setShow] = useState(false);
    const [id, setId] = useState(0);
    const [companyType, setCompanyType] = useState("");
    const [companyTitle, setCompanyTitle] = useState("");
    const [Tc, setTc] = useState("");
    const [taxNo, setTaxNo] = useState("");
    const [companyRegistrationNo, setCompanyRegistrationNo] = useState("");
    const [companyMersisNo, setCompanyMersisNo] = useState("");
    const [companyTaxOffice, setCompanyTaxOffice] = useState("");


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const loggedInStatus = localStorage.getItem('isLoggedIn');
    const getSellersWithPendingDocs = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/file/get-pending-sellers`
        return axios.get(finalUrl, { withCredentials: "true" })
    }
    const { isLoading, isError, error, data } = useQuery({ queryKey: ["get-pending-sellers"], queryFn: getSellersWithPendingDocs });
    let pendingSellers
    if (isLoading) {
        pendingSellers = "loading"
    } else if (isError) {
        if (error.response.data.detail[0].msg === "not found") {
            pendingSellers = "not found"
        } else {
            pendingSellers = "error"

        }
        console.log(error);
    } else {
        pendingSellers = data?.data
        // console.log(pendingSellers);
    }


    return (
        <>
            <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 ">
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#000" size={50} />
                </Link>
        
                <Row className="d-flex justify-content-center align-items-center   ">
                    <Col style={{ width: "90vw" }} className="d-flex justify-content-center align-items-center ">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Seller ID</th>
                                    <th>Company Type</th>
                                    <th>Company Title</th>
                                    <th>Seller TC No</th>
                                    <th>Tax No</th>
                                    <th>Registration No</th>
                                    <th>Mersis No</th>
                                    <th>Tax Office</th>
                                    <th>Pending Docs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pendingSellers === "loading" ? (
                                    <tr>
                                        <td colSpan="9">loading
                                            {/* <Spinner animation="border" variant="secondary" /> */}
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="secondary"
                                            />
                                        </td>

                                    </tr>
                                ) : pendingSellers === "not found" ?
                                    (
                                        <tr>
                                            <td colSpan="9">not found</td>
                                        </tr>
                                    )
                                    : pendingSellers === "error" ? (
                                        <tr>
                                            <td colSpan="9">error</td>
                                        </tr>
                                    ) : (Array.isArray(pendingSellers) && pendingSellers.length > 0 && pendingSellers.map((item) => {
                                        return (
                                            <tr onClick={() => {
                                                handleShow()
                                                setId(item.seller_id)
                                                setCompanyType(item.company_type)
                                                setCompanyTitle(item.company_title)
                                                setTc(item.seller_TC_no)
                                                setTaxNo(item.company_tax_no)
                                                setCompanyRegistrationNo(item.company_registration_no)
                                                setCompanyMersisNo(item.company_mersis_no)
                                                setCompanyTaxOffice(item.company_tax_office)
                                            }} key={item.seller_id}>
                                                <td> {item.seller_id} </td>
                                                <td> {item.company_type} </td>
                                                <td> {item.company_title} </td>
                                                <td> {item.seller_TC_no} </td>
                                                <td> {item.company_tax_no} </td>
                                                <td> {item.company_registration_no} </td>
                                                <td> {item.company_mersis_no} </td>
                                                <td> {item.company_tax_office} </td>
                                                <td> {item.pending_docs} </td>
                                            </tr>
                                        );
                                    }))}


                            </tbody>
                        </Table>
                        <DocDetailModal
                            companyType={companyType}
                            companyTitle={companyTitle}
                            Tc={Tc}
                            taxNo={taxNo}
                            companyRegistrationNo={companyRegistrationNo}
                            companyMersisNo={companyMersisNo}
                            companyTaxOffice={companyTaxOffice}
                            id={id}
                            show={show}
                            onHide={handleClose}
                        />

                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default Document